<script>
import FormatDate from '@/mixins/formatDate.js'

export default {
  name: 'RemainingDays',
  mixins: [FormatDate],
  props: {
    endDate: { required: false, type: String },
    startDate: { required: false, type: String },
    context: { required: false, type: String, default: 'card' },
    ressourceExpired: { required: false, type: Boolean, default: false }
  },
  computed: {
    formattedEnd () {
      return this.endDate ? this.$dayjs(this.endDate).locale(this.$i18n.locale) : null
    },
    formattedStart () {
      return this.startDate ? this.$dayjs(this.startDate).locale(this.$i18n.locale) : null
    },
    getColorFromRemainingDays () {
      if (this.remainingDays) {
        if (this.remainingDays > 10) {
          return 'success'
        } else if (this.remainingDays < 10 && this.remainingDays > 5) {
          return 'orangered'
        } else {
          return 'red'
        }
      } else {
        return null
      }
    },
    remainingDays () {
      const now = this.$dayjs()
      const to = this.$dayjs(this.endDate ? this.endDate : this.startDate)
      const remaining = now.diff(to, 'days')
      return remaining === 0 ? 1 : -remaining
    }
  }
}
</script>
<template lang="pug">
div

  //- EXPIRED
  v-chip(v-if="ressourceExpired" small color='red' outlined style='background-color:white !important')
    div.flex.items-center
      font-awesome-icon.f5.mr2(:icon="['fad', 'calendar-alt']")
      span {{ $t('lecture.expired') }}

  //- X JOURS RESTANTS
  div.align-days-presentation(v-else-if='context === "presentation"')
    span.days-presentation-chiffre(v-if='remainingDays <= 30') {{Math.abs(remainingDays)}}
    span.days-presentation(v-if='remainingDays >= 0 && remainingDays <= 30') {{$tc('partage.jours-restants', remainingDays) }}
    span.days-presentation(v-else-if='remainingDays < 0') {{$tc('partage.jours-retards', Math.abs(remainingDays)) }}

  //- DATE FIN
  v-chip(v-else-if='context === "card"', small, :color='getColorFromRemainingDays', outlined, style='background-color:white !important;')
    div.flex.items-center
      font-awesome-icon.f5.mr2(:icon="['fad', 'calendar-alt']")
      span.mr-1(v-if='remainingDays < 0') {{ $t('calendrier.expire-depuis') }} &nbsp;
      span {{ formattedEnd.format('DD MMM YYYY') }} &nbsp;

  //- ACCESSIBLE DURANT X JOURS
  div.f7.mt2(v-else-if='context === "cahier"')
    template(v-if='endDate')
      span(v-if='remainingDays >= 1 && remainingDays <= 30') {{ $tc('messages.accessible-durant', remainingDays, { remainingDays }) }}
      span(v-else-if='remainingDays > 30') {{ $t('messages.accessible-jusque') }} {{ formattedEnd.format('dddd DD MMM YYYY') }}
      span(v-else) {{ $t('calendrier.expire') }}
    template(v-else-if='startDate')
      span {{ $t('messages.accessible-partir') }} {{ formattedStart.format('dddd DD MMM YYYY') }}

  //- DATE FIN
  v-chip.primary.lighten-4.secondary--text(v-else, small, outlined, style='background-color:white !important;')
    div.flex.items-center
      font-awesome-icon.f5.mr2(:icon="['fad', 'calendar-alt']")
      span {{ formattedEnd.format('DD MMM YYYY') }}
</template>

<style lang='sass'>
.days-presentation-chiffre
  font-size: 72px
  font-weight: $bold
  font-family: $display-font
  color: white
.days-presentation
  font-size: 18px
  font-weight: $bold
  font-family: $display-font
  color: white
.align-days-presentation
  display: flex
  flex-direction: column
  align-content: center
  align-items: center

@media all and (max-width: $large)
  .days-presentation-chiffre
    font-size: 48px
  .days-presentation
    font-size: 12px

</style>
