export default {
  computed: {
    isFav () {
      if (this.produit && this.produit.__typename === 'BoutiqueItem') {
        return this.$store.state.User.favoris.find(e => e.favori && e.favori.id === this.produit.id)
      } else if (this.produit) {
        return this.$store.state.User.favoris.find(e => e.meta && e.meta.includes(this.produit.id))
      } else {
        return false
      }
    }
  },
  methods: {
    async toggleFavBoutique (item) {
      console.log('toggle fav boutique', item)
      let error = false
      let retourId = null
      let isFav = this.produit ? this.isFav : this.$store.state.User.favoris.find(e => e.id === item.id || (e.favori && e.favori.id === item.id) || (e.meta && e.meta.includes(item.id)))
      const ajoutFav = async (item, type) => {
        let varMut = type === 'BoutiqueItem' ? { favori: { id: item.id, type: 'BOUTIQUE_ITEM' } } : { meta: JSON.stringify({ version: 1, data: { type: 'glossaire_graph', id: item.id } }) }
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/createCommunFavori.gql'),
          variables: varMut
        }).then(({ data }) => {
          if (!data.createCommunFavori || data.createCommunFavori.errors) {
            const errorMsg = { msg: 'erreur ajout favori' }
            throw errorMsg
          } else {
            retourId = data.createCommunFavori.id
          }
        })
      }
      const removeFav = async (item) => {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/destroyCommunFavori.gql'),
          variables: { id: item }
        }).then(({ data }) => {
          if (!data.destroyCommunFavori || data.destroyCommunFavori.errors) {
            const errorMsg = { msg: 'erreur retrait favori' }
            throw errorMsg
          } else {
            retourId = data.destroyCommunFavori.id
          }
        })
      }
      try {
        if (isFav) {
          await removeFav(isFav.id, item.__typename)
        } else {
          await ajoutFav(item, item.__typename)
        }
      } catch (e) {
        error = true
        console.error('erreur lors de lajout/modification du favoris', e)
      } finally {
        if (!error) {
          if (item.__typename === 'BoutiqueItem') {
            this.$store.commit('User/addRemoveFavoris', { id: retourId, favori: { id: item.id }, meta: null })
          } else {
            this.$store.commit('User/addRemoveFavoris', { id: retourId, favori: null, meta: JSON.stringify({ version: 1, data: { type: 'glossaire_graph', id: item.id } }) })
          }
        }
      }
    }
  }
}
