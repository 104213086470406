// To avoid any conflicts with other *.dokoma.com website (such as eleve.dokoma.com)
// We want to use a specific cookie in Enseignant that contains the bearer
// Previously, all sites used the same 'ClassDokomaToken' cookie

// Since we want to isolate the Enseignant cookie, we still need to copy
// the Bearer into to 'ClassDokomaToken' cookie before going to external url

/* Usage
import openLink from '@/mixins/openLink.js'
  mixins: [openLink],
*/

import VueCookies from 'vue-cookies'

export default {
  methods: {
    writeTokenInCookiesAndOpenLink (linkUrl, openInNewTab = true) {
      const bearer = VueCookies.get('classParentToken')
      // VueCookies.set('ClassDokomaToken', bearer, 60 * 60 * 24 * 7, '/', process.env.VUE_APP_DOMAIN)
      let domain = window.location.hostname
      if (domain !== 'localhost') {
        const data = domain.split('.')
        data[0] = ''
        domain = data.join('.')
      }
      VueCookies.set('ClassDokomaToken', bearer, 60 * 60 * 24 * 7, '/', domain)
      openInNewTab ? window.open(linkUrl, '_blank') : window.open(linkUrl)
    }
  }
}
