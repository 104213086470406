<script>
import ProduitCard from '@/components/presentation/produitCard.vue'
import TableauPresentation from '@/components/tableauxBlancs/tableauPresentation.vue'
import DupliquerAchatTableau from '@/components/dialogs/tableauBlanc/dupliquerAchatTableau.vue'
// import ContentHandleList from '@/components/bibliotheque/contentHandleList.vue'

export default {
  name: 'TableauxAchats',
  components: {
    TableauPresentation,
    ProduitCard,
    DupliquerAchatTableau
    // ContentHandleList
  },
  data: () => ({
    totalAchats: null,
    allAchats: [],
    fetchingAchats: true,
    selectedTableauId: '',
    showDuplicationDialog: false
  }),
  computed: {
    selectedSlug () {
      return this.$route?.query?.t
    },
    selectedAchat () {
      return this.fetchingAchats ? null : this.allAchats.find(a => a.boutiqueItem?.slug === this.selectedSlug)
    },
    emptyListLabel () {
      return this.fetchingAchats ? 'Chargement en cours...' : 'Vous ne possédez aucun Tableau Blanc acheté pour le moment.'
    },
    dossiers () {
      return this.selectedAchat?.boutiqueItem?.contenus ?? []
    },
    itemSlug () {
      return this.selectedAchat?.boutiqueItem?.slug
    },
    itemTitre () {
      return this.selectedAchat?.boutiqueItem?.titre
    },
    actifTableaux () {
      return this.allAchats.filter(p => p.finAt === null || this.$dayjs().isBefore(p.finAt))
    }
  },
  methods: {
    selectDossierForDuplication (dossier) {
      // In the futur where a board will be composed of multiple TableauBlanc
      // This might need to be rethought
      this.selectedTableauId = dossier.tableauxBlancs?.[0]?.id
      this.showDuplicationDialog = true
    }
  },
  apollo: {
    searchNuage: {
      query: require('@/graphql/queries/v2/searchNuageItems.gql'),
      variables () {
        return {
          page: 1,
          limit: 24,
          tri: [{ sujet: 'CREATED_AT', direction: 'DESC' }],
          filtre: {
            formats: ['config-boutique-types-de-formats-tableaux-blancs']
          }
        }
      },
      update (data) {
        const res = data.searchNuageItems
        this.totalAchats = res.total
        const resultatsNotAlreadySaved = res.resultat.filter(r => this.allAchats.findIndex(a => a.id === r.id) === -1)
        this.allAchats = [...this.allAchats, ...resultatsNotAlreadySaved]
        this.fetchingAchats = false
        return data.searchNuageItems
      },
      fetchPolicy: 'cache-and-network'
    }
  }
}
</script>

<template lang="pug">
.bibliotheque-index.split-page
  div.biblio-wrapper
    //- content-handle-list(origin='tableauxBlancAchats', :produits='allAchats')
    .produits-showcase
      .produit-index.split-page(v-if="!selectedSlug")
        .page-header(color='primary')
          .header__inner-content
            .header-title()
              //- div.secondary-title Sous-titre
              div.main-title Tableaux blancs achetés
        .page-actions
          v-card(color='primary' href="https://boutique.dokoma.com/fr/" target="_blank" v-ripple)
            v-card-title Acheter un tableaux blanc
        .page__main-content
          section.fullsize-section
            .section-subtitle__header
              //- h2.section-subtitle {{ $t('tableaux.tout-tableau') }}
            .produit-cards__wrapper(v-if="actifTableaux && actifTableaux.length")
              v-lazy(v-for='(tableau, i) in actifTableaux' :key='i' :options="{ threshold: .08 }" min-height="325")
                produit-card.animated.fadeIn(:produit='tableau.boutiqueItem ? tableau.boutiqueItem : tableau' :debut='tableau.debutAt' :finAt='tableau.finAt' context="tableaux")
            template(v-else)
              div.f4.tc.pa4 {{ emptyListLabel }}
      template(v-else)
        tableau-presentation.ml2(
          :key='$route.query.t'
          :nuage='selectedAchat'
        )
        .page__main-content.mt3.animated.fadeIn
          section.fullsize-section
            .section-card.dossier-list-container
              .dossier-item(v-for="(dossier, index) in dossiers" :key="dossier.id")
                v-list-item
                  v-list-item-content.pr-3
                    v-list-item-title {{ dossier.titre }}
                    v-list-item-subtitle(v-html="dossier.description")
                  v-btn.my-2(color="primary" @click="selectDossierForDuplication(dossier)") Utiliser le contenu de ce tableau blanc
                v-divider(v-if="index < dossiers.length - 1" :inset="false")

      v-dialog(v-model="showDuplicationDialog" max-width="600" content-class='custom-dialog')
        dupliquer-achat-tableau(:tableauId="selectedTableauId" :titre="itemTitre" :itemSlug="itemSlug")

</template>

<style lang="sass">
@import 'src/styles/pages/_bibliotheque'
@import 'src/styles/components/bibliotheque/_produit'

</style>

<style lang="sass" scoped>
.bibliotheque-index
  .biblio-wrapper
    .section-card.dossier-list-container
      background-color: #fff
      width: 100%
      border-radius: 12px
      .v-list-item__subtitle::v-deep
        p
          margin: 0
    .produits-showcase
    .split-page
      height: auto
  .v-list-item
    flex-direction: column
    &::after
      display: none
    .v-list-item__subtitle
      white-space: break-spaces

</style>
