<script>
export default {
  name: 'TextPattern',
  components: {
  },
  props: {
    quantity: { required: false, type: Number, default: 400 },
    data: { required: true, type: String, default: 'Ceci est un test' },
    angle: { required: false, type: Number, default: -15 },
    size: { required: false, type: Number, default: 60 },
    color: { required: false, type: String, default: 'red' },
    opacity: { required: false, type: Number, default: 0.25 }
  },
  data () {
    return {
    }
  },
  computed: {
    patternText () {
      let builtSentence = ''
      for (let i = 0; i <= this.quantity; i++) {
        builtSentence += ('' + this.data + ' ')
      }
      return builtSentence
    }
  },
  apollo: {
  },
  methods: {
  }
}
</script>

<template lang="pug">
  .text-pattern(:style='{"opacity" : opacity}')
    div.text-wrapper(:style='{transform: "rotate(" + angle + "deg)" + " perspective(0px) skew(0deg,0deg)"}')
      //- p(:style='{transform: "rotate(" + angle + "deg)" + " perspective(0px) skew(5deg,0deg)"}')
      p()
        span(:style='{fontSize: size + "px", color: color}') {{patternText}}

</template>

<style lang='sass'>
  .text-pattern
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 0
    // overflow: hidden
    .text-wrapper
      // opacity: 0.15
      position: absolute
      top: -25%
      left: -25%
      width: 200%
      height: 200%
      transform-origin: center center
      // position: absolute
      display: block
      flex-wrap: wrap
      // font-size: 20px
      font-family: $display-font
      font-weight: $bold
      span
        transform-origin: center center
        margin-right: 20px
        white-space: normal
        word-wrap: break-word
</style>
