<script>
import openLink from '@/mixins/openLink.js'
import RemainingDays from '@/components/shared/remainingDays.vue'

export default {
  name: 'TableauPresentation',
  components: {
    RemainingDays
  },
  mixins: [openLink],
  props: {
    nuage: { required: false, type: Object, default: () => null },
    contenuProps: { required: false, type: Array, default: () => null },
    titreAndDescription: { required: false, type: Object }
  },
  data: () => ({
    expendDescription: false,
    showDuplicationDialog: false
  }),
  methods: {
    getFontSize (mot) {
      return mot.length <= 50 ? '2em' : '1.75em'
    },
    goToShop () {
      let temp = 'https://boutique.dokoma.com/fr/produits/' + this.nuage.boutiqueProduits[0].slug + '/' + this.nuage.boutiqueItem.slug
      this.writeTokenInCookiesAndOpenLink(temp)
    }
  },
  computed: {
    contenus () {
      return this.contenuProps ?? this.produit?.contenus
    },
    isExpired () {
      if (this.nuage?.finAt) {
        const now = this.$dayjs()
        const to = this.$dayjs(this.nuage.finAt)
        return now.diff(to, 'days') > 0
      }
      return false
    },
    format () {
      const f = this.produit?.formats
      return f?.length > 0 && f[0]?.titre ? f[0].titre : this.$t('menu.tableaux')
    },
    produit () {
      return this.nuage?.boutiqueItem ?? null
    },
    imagesList () {
      let images = {}
      if (this.produit?.images?.length) {
        this.produit.images.forEach(img => {
          images.type = 'sku'
          if (img.tagsVisibles && img.tagsVisibles.find(tag => tag.id === 'details-graphiques_image-couverture')) {
            images.coverImage = img
          } else if (img.tagsVisibles && img.tagsVisibles.find(tag => tag.id === 'details-graphiques_image-pictogramme')) {
            images.pictogramme = img
          }
        })
      } else {
        images.type = 'produit'
        if (this.produit?.image) {
          images.coverImage = this.produit.image
        }
        if (this.produit?.image_picto) {
          images.pictogramme = this.produit.image_picto
        }
      }
      return images
    },
    nbPages () {
      let nbPages = 0
      if (this.contenus) {
        this.contenus.forEach((contenu) => {
          nbPages += contenu?.tableauxBlancs?.reduce((acc, curr) => acc + curr.nbPage, 0)
        })
      }
      return nbPages
    },
    tableauTitre () {
      return this.titreAndDescription?.titre ?? this.produit?.titre
    },
    tableauDescription () {
      return this.titreAndDescription?.description ?? null
    },
    backLink () {
      if (this.contenuProps) {
        return {
          to: { name: 'tableau', params: { lang: this.$i18n.locale } },
          label: this.$t('tableaux.tout-tableau')
        }
      } else {
        return {
          to: { name: 'tableauxAchats___' + this.$i18n.locale },
          label: this.$t('tableaux.all-models')
        }
      }
    },
    editeurName () {
      return this.nuage?.boutiqueProduits?.[0]?.editeur?.titre
    }
  }
}
</script>

<template lang="pug">
.devoirs-index.split-page(v-if='tableauTitre')

  v-sheet.page-header(color='secondary')

    //- HEADER
    .header-image
      v-img.w-100.h-100(v-if='imagesList && imagesList.type === "sku" && imagesList.coverImage', :src='imagesList.coverImage.large_image', :lazy-src='imagesList.coverImage.thumbnail')
      v-img.w-100.h-100(v-else-if='imagesList && imagesList.type === "produit" && imagesList.coverImage', :src='imagesList.coverImage.image_type === "Svg" ? imagesList.coverImage.medium_image : "https://plus.dokoma.com" + imagesList.coverImage.large_image', :lazy-src='imagesList.coverImage.image_type === "Svg" ? imagesList.coverImage.medium_image : "https://plus.dokoma.com" + imagesList.coverImage.large_image')
    .header__inner-content
      div.flex.justify-between.items-center.w-100.mb3
        v-btn.white--text(:small='$store.state.App.mobileTemplate', :to="backLink.to", rounded, color='primary')
          font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
          span {{ backLink.label }}
        div.flexjours
          remaining-days(v-if='nuage.finAt && !isExpired', :endDate='nuage.finAt', context='presentation')
      div.flex-grow-1
      template
        .d-flex.justify-start.align-center
          v-avatar.mb1.relative(v-if='imagesList && imagesList.pictogramme', size='45', color='transparent')
            div.color-fallback()
            v-img(v-if='imagesList.type === "sku"', :src='imagesList.pictogramme.thumbnail')
            v-img(v-else-if='imagesList.type === "produit"', :src='imagesList.pictogramme.image_type === "Svg" ? imagesList.pictogramme.url_to_image : "https://plus.dokoma.com" + imagesList.pictogramme.url_to_image')
          .white--text.f4.ml-3(v-if="editeurName") {{ $t('tableaux.by-x', { name: editeurName }) }}
        .header-title()
          div.secondary-title {{format}}
          div.main-title(:style="{ fontSize: getFontSize(tableauTitre)}") {{tableauTitre}}
          .header__extra-content(v-if="tableauDescription") {{ tableauDescription }}
          .header__extra-content
            remaining-days(v-if='nuage.finAt', :endDate='nuage.finAt', context='card')
            v-chip.primary.lighten-5.secondary--text.mt1(small)
              font-awesome-icon.f6.mr2(:icon="['fad', 'file-edit']")
              span {{ $tc('lecture.nombre-page', nbPages, {page: nbPages}) }}

  //- ACTION BUTTONS
  .page-actions
    v-card(v-if='isExpired' color='primary' v-ripple @click="goToShop")
      v-card-title {{ $t('tableaux.buy-again')}}

  //- DESCRIPTION
  .mt3.animated.fadeIn
    section.fullsize-section(v-if='produit.descriptionNuage || (produit.public && produit.descriptionPublic) || (!nuage && produit.description)')
      div.relative.mb3
        v-expand-transition
          v-card.transparent(v-show='expendDescription', height='100%', width='100%', flat)
            template(v-if='nuage')
              div.produit-description(v-if='produit.public && produit.descriptionPublic', v-html='produit.descriptionPublic')
              div.produit-description(v-else-if='produit.descriptionNuage', v-html='produit.descriptionNuage')
            template(v-else-if='produit.description')
              .produit-description(v-html='produit.description')
        v-btn(@click='expendDescription = !expendDescription', text, block, small, rounded ,outlined)
          | {{expendDescription ? $t('action.masque-description') : $t('action.affiche-description') }}
          font-awesome-icon.ml2(:icon="['fas', expendDescription ? 'caret-up' : 'caret-down']", style='font-size: 18px')

</template>

<style lang="sass" scoped>
.page__main-content
  margin: 20px 0 0 0
</style>
