<script>
export default {
  name: 'DupliquerAchatTableau',
  props: {
    tableauId: { type: String, required: true },
    titre: { type: String, required: true },
    itemSlug: { type: String, required: true }
  }
}
</script>

<template lang="pug">
v-card.light.dupliquer-achat-tableau
  v-card-title.dialog-title.secondary.white--text Utiliser ce contenu
  v-card-text.relative
    div Vous êtes actuellement sur la page d'un modèle de tableau blanc.
    div  Afin de pouvoir l'éditer, vous devez en créer une copie éditable, qui apparaitra alors dans la section \"Mes tableaux\".
    v-btn.mt-4.mb-2.w-100(:to="{ name: 'editeurTableau', query: { t: tableauId, titre, itemSlug }}" depressed color='primary') Créer une copie éditable
</template>
